<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <div class="orchestarBg">
        <div class="p20">
          <img v-if="$i18n.locale == 'zh'" src="@/assets/t12.png" width="180" />
          <img v-else src="@/assets/t12e.png" width="250"/>
          <div class="boxText" style="margin-top:16px;">
            <p v-html="$t('introductory.title1')">
            </p>
          </div>
        </div>
        <video
          class="video"
          controls
          src="https://c.vod.suzhousco.com/sv/52261218-17e47235ee6/52261218-17e47235ee6.mp4"
        >
          your browser does not support the video tag
        </video>
        <!-- <swiper class="orchestraOptions" :options="orchestraOptions">
          <swiper-slide>
            <img src="@/assets/ov_04.jpg" />
          </swiper-slide>
          <div class="orchestraOptionsNext"></div>
          <div class="orchestraOptionsPrev"></div>
        </swiper> -->
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      isIndex: false,
      orchestraOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".orchestraOptionsNext",
          prevEl: ".orchestraOptionsPrev",
        },
        // Some Swiper option/callback...
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    joinView(index) {
      this.joinList[index].show = !this.joinList[index].show;
    },
    // orchestraOptions: {
    //     slidesPerView: 1,
    //     spaceBetween: 0,
    //     centeredSlides: true,
    //     loop: true,
    //     navigation: {
    //       nextEl: '.orchestraOptionsNext',
    //       prevEl: '.orchestraOptionsPrev',
    //     },
    //   // Some Swiper option/callback...
    // },
    // getCompanys(){

    // }
  },
};
</script>

<style scoped>
.orc-img {
  width: 100%;
}
.video {
  width: 100%;
}
</style>

